import { gql } from '@apollo/client'

export const GET_GHOST_LISTING = gql`
  query GET_GHOST_LISTING($id: Int!) {
    dealerListing: GhostListing(id: $id) {
      id
      isHidden
      comments
      seatCapacity
      vehicleVfactsPrice: vfactsPricingSegment
      vehicleVfactsClass: vfactsClass
      vehicleVfactsSegment: vfactsSegment
      badgeDescription
      badgeSecondaryDescription
      bodyStyleDescription
      bodyConfigDescription
      vehicleKey: redbookVehicleKey
      makeName: makeDescription
      modelName: familyDescription
      vehicleBadgeDescription: badgeDescription
      createdAt
      updatedAt
      year: yearGroup
      variant: description
      fuelType: fuelTypeDescription
      body: bodyType
      numColours: numUniquePrimaryColours
      priceDriveAway: newPrice
      priceExcludingGovtCharges: newPrice

      colours: uniquePrimaryColours {
        id
        primaryGenericColour
        primaryShade
        primaryType
        primaryManufacturerDescription
      }

      redbookVehicle: RedbookVehicle {
        fuelType {
          id
          name
        }
        currentRelease
        vehicleDoorNum: doorNum
        vehicleKey
        vehicleBadge: attributes {
          shortDescription
        }
        variantUuid: uuid
        vehicleDescription: description
        vehicleFamilyCode: familyCode
        vehicleMakeCode: makeCode
        vehicleModel: RedbookFamily {
          description
          uuid
          slug
        }
        engineSize
        transmission: gearTypeDescription
        vehicleModelCode: familyCode
        vehicleSeriesModelYear: seriesModelYear
        vehicleYearGroup: yearGroup
        vehicleMake: RedbookMake {
          description
          uuid
          slug
        }
        vehicleModel: RedbookFamily {
          uuid
        }
        redbookPhotos: photos {
          id
          externalId
          redbookPhotoId
          srcUrl: url
          redbookPhoto {
            view
          }
        }
        kerbWeight
        vehicleLimitedEdition: limitedEdition
        vehicleBuildCountryOriginDescription: buildCountryOriginDescription
        vehicleSeriesPublic: seriesPublic
        manufacturerBodyStyle: manufacturerBodyStyle
        manufacturerBodyConfig: manufacturerBodyConfig
        engineTypeDescription: engineTypeDescription
        vehicleCombinedPower: combinedPower
        attributes {
          shortDescription
        }
      }
    }
  }
`

export const enum BlockType {
  CORE_COVER = 'core/cover',
  CORE_EMBED = 'core/embed',
  CORE_LIST = 'core/list',
  CORE_MEDIA_TEXT = 'core/media-text',
  CORE_PARAGRAPH = 'core/paragraph',
  ACF_INFOGRAPHICS = 'acf/infographics-block',
  ACF_TYPEFORM = 'acf/typeform-block',
  YOAST_FAQ = 'yoast/faq-block',
  DRIVE_TESTIMONIALS = 'drive-blocks/drive-testimonials',
  DRIVE_SPONSORED_CONTENT = 'drive-blocks/sponsored-content',
  DRIVE_VIDEO_GALLERY = 'drive-blocks/video-gallery',
  DRIVE_ARTICLE_POST_SLIDER = 'drive-blocks/article-post-slider',
  DRIVE_FEATURED_HERO = 'drive-blocks/featured-hero',
  DRIVE_FEATURED_POST = 'drive-blocks/featured-post-block',
  DRIVE_MULTI_CARLIST_MODEL_TABLE = 'drive-blocks/multi-carlist-model-table',
  DRIVE_DRIVE_CONTENT_EXPLORER = 'drive-blocks/drive-content-explorer',
  DRIVE_GALLERY = 'drive-blocks/gallery',
  DRIVE_COUNTERPOINT = 'drive-blocks/counterpoint',
  DRIVE_CURATED_CAR_LIST = 'drive-blocks/curated-car-list',
  DRIVE_CHARGING_CALCULATOR = 'drive-blocks/charging-calculator',
  DRIVE_JUMP_LINKS = 'drive-blocks/jump-links',
  DRIVE_YOU_TUBE = 'drive-blocks/you-tube',
  DRIVE_CATEGORY_RATING = 'drive-blocks/category-rating',
  DRIVE_MODEL = 'drive-blocks/model',
  DRIVE_MODEL_LOVE_AND_LIKE = 'drive-blocks/model-love-and-like',
  DRIVE_VEHICLE_OPINION = 'drive-blocks/vehicle-opinion',
  DRIVE_MULTI_CAR_SNAPSHOT = 'drive-blocks/drive-multi-car-snapshot',
  DRIVE_ENQUIRY_FORM = 'drive-blocks/enquiry-form',
  DRIVE_VIDEO = 'drive-blocks/video',
  DRIVE_PAST_WINNERS = 'drive-blocks/past-winners',
  DRIVE_DCOTY_CATEGORY = 'drive-blocks/dcoty-category',
  DRIVE_OUR_JUDGES = 'drive-blocks/our-judges',
  DRIVE_COVER = 'drive-blocks/cover',
  DRIVE_OVERALL_WINNER = 'drive-blocks/overall-winner',
  DRIVE_IMAGE = 'drive-blocks/image',
  DRIVE_BP_FUEL_CONSUMPTION = 'drive-blocks/bp-fuel-consumption',
  DRIVE_BP_CHARGING_STATIONS = 'drive-blocks/bp-charging-stations',
  DRIVE_LATEST_POSTS_BY_TAG = 'drive-blocks/latest-posts-by-tag',
  DRIVE_CROSS_PAGE_NAVIGATION = 'drive-blocks/cross-page-navigation',
  DRIVE_MODEL_TABLE = 'drive-blocks/model-table',
  DRIVE_CTA_LINK = 'drive-blocks/cta-link',
  DRIVE_IMAGE_POST_SLIDER = 'drive-blocks/image-post-slider',
  DRIVE_MARKETPLACE_LISTINGS = 'drive-blocks/drive-marketplace-listings',
  DRIVE_CAR_FINDER = 'drive-blocks/drive-car-finder',
  DRIVE_GUIDES_BANNER = 'drive-blocks/drive-guides-banner',
  DRIVE_DISCOVER_360 = 'drive-blocks/drive-discover-360',
  DRIVE_CAR_LOAN_CALCULATOR = 'drive-blocks/drive-car-loan-calculator',
  DRIVE_MULTI_SPECS_TABLE = 'drive-blocks/multi-specs-table',
  DRIVE_DEALS = 'drive-blocks/deals',
  DRIVE_LEASE_MODEL_TABLE = 'drive-blocks/lease-model-table'
}

import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react'
import { getScrollPosition, useScrollPosition } from '../../utils/hooks/useScrollPosition'
import cx from 'classnames'
import styles from './roofrack.module.scss'
import AdSpacing from '../common/ads/AdSpacing'
import Ad from '../../components/common/ad-units/ad'
import { TEMP_STICKY_STATUS } from '../../constants/layout'
import { usePageTakeOver } from '@grille/utils/hooks/usePageTakeOver'
import useHeaderMenuIsVisible from '@grille/utils/hooks/useHeaderMenuIsVisible'
/**
 * Subcomponent of layout
 */
const Roofrack: FunctionComponent<RoofrackProps> = ({
  adWrapperClassName,
  adClassName,
  extraAdClassName,
  extraAdId,
  hideAds,
  hideRoofRackAdPageStore,
  adStatus,
  setAdStatus,
  tempStickyRoofrack,
  adId,
  adBgColor,
  mobilePersistStickyHeader,
  openedPanel,
  shouldRenderRoofrack = true,
  wrapperClassname = '',
  homepage
}) => {
  const pageTakeOver = usePageTakeOver()
  const [tempStickyRoofRackStatus, setTempStickyRoofRackStatus] = useState<string | null>(null)
  const [isWindow, setIsWindow] = useState(false)
  const tempStickyScrollPx: number = 350
  const { headerMenuIsVisible } = useHeaderMenuIsVisible()

  const untrackScrollPosition: () => void = useScrollPosition(
    //set tempstickyroorackstatus from counting to done once scrolled down tempStickyScrollPx
    ({ currPos }: { currPos: { y: number } }) => {
      if (
        tempStickyRoofRackStatus === TEMP_STICKY_STATUS.COUNTING &&
        currPos.y > tempStickyScrollPx
      ) {
        setTempStickyRoofRackStatus(TEMP_STICKY_STATUS.DONE)
      }
    },
    null,
    null,
    true,
    100
  )

  //decides whether to apply tempsticky once page is loaded
  useEffect(() => {
    if (tempStickyRoofrack && tempStickyRoofRackStatus === null) {
      const posY: number = getScrollPosition({
        element: null,
        useWindow: true,
        boundingElement: null
      })?.y
      //only start being tempsticky and countdown if started position is within tempStickyScrollPx
      if (posY < tempStickyScrollPx) {
        setTempStickyRoofRackStatus(TEMP_STICKY_STATUS.COUNTING)
      } else {
        //outside range, no need to track
        untrackScrollPosition()
      }
    }
  }, [tempStickyRoofRackStatus, tempStickyRoofrack, untrackScrollPosition])

  useEffect(() => {
    if (tempStickyRoofRackStatus === TEMP_STICKY_STATUS.DONE) {
      untrackScrollPosition()
    }
  }, [tempStickyRoofRackStatus, untrackScrollPosition])

  useEffect(() => {
    typeof window !== 'undefined' && setIsWindow(true)
  }, [])

  return (
    <div
      id='roofrack'
      style={hideAds || hideRoofRackAdPageStore ? { position: 'static' } : undefined}
      className={cx(
        [
          adWrapperClassName && isWindow ? adWrapperClassName : styles['roof-rack__ad-bg'],
          adBgColor && extraAdId ? styles['roof-rack__ad-bg__color'] : null,
          {
            [styles['roof-rack__ad-bg--mobile-persist-sticky']]: mobilePersistStickyHeader,
            [styles['roof-rack__ad-bg--mobile-persist-sticky__under-header']]: headerMenuIsVisible,
            [styles['roof-rack__ad-bg--temp-sticky']]:
              tempStickyRoofRackStatus === TEMP_STICKY_STATUS.COUNTING,
            [styles['roof-rack__ad-bg--temp-sticky-under-header']]:
              tempStickyRoofRackStatus === TEMP_STICKY_STATUS.COUNTING && openedPanel,
            [styles['roof-rack__ad-bg--temp-sticky-done']]:
              tempStickyRoofRackStatus === TEMP_STICKY_STATUS.DONE
          },
          { [styles['roof-rack__ad-bg--sticky-roof-rack']]: pageTakeOver }
        ],
        'roofrack-wrapper',
        wrapperClassname ?? ''
      )}
    >
      {extraAdId ? (
        <AdSpacing
          customStyle={{
            paddingTop: homepage ? '23px' : pageTakeOver ? '15px' : '0',
            paddingBottom: pageTakeOver ? '15px' : '0'
          }}
          className='layout-extra-ad'
        >
          <Ad
            id={extraAdId}
            className={extraAdClassName}
            onLoadHandle={(data: Record<string, any>) => {
              if (data?.isLoaded && !adStatus.isLoaded) {
                setAdStatus({ adElRefCurrent: data?.adElRefCurrent ?? null, isLoaded: true })
              }
            }}
          />
        </AdSpacing>
      ) : null}
      {shouldRenderRoofrack && (
        <AdSpacing type='roofrack'>
          <div className={cx(styles['roof-rack__ad-wrapper'], 'gam-ad-wrapper gam-roofrack-pos1')}>
            <Ad id={adId || 'gam_roofrack_pos1'} className={adClassName} onLoadHandle={undefined} />
          </div>
        </AdSpacing>
      )}
    </div>
  )
}

type RoofrackProps = {
  adWrapperClassName?: string
  wrapperClassname?: string
  adClassName?: string
  hideAds?: boolean
  shouldRenderRoofrack?: boolean
  hideRoofRackAdPageStore?: boolean
  extraAdClassName?: string
  extraAdId?: string
  adId?: string
  adStatus: { adElRefCurrent: boolean; isLoaded: boolean }
  setAdStatus: Dispatch<
    SetStateAction<{
      adElRefCurrent: null
      isLoaded: boolean
    }>
  >
  tempStickyRoofrack?: boolean
  adBgColor?: string | false
  mobilePersistStickyHeader?: boolean
  openedPanel: boolean
  hideroofRack: boolean
  homepage?: boolean
}

export default Roofrack

import cx from 'classnames'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useAdSlot } from './useAdSlot'
import { useTransitionState } from './transitions'
import logger from '../../../utils/logger'
import isFunction from 'lodash/isFunction'
import isEmpty from 'lodash/isEmpty'
import styles from './ad.module.scss'
import { determineAdSize } from '@grille/utils/functions/ads/DetermineAdSize'
import { AdSize } from '@grille/types/adSize'

function Ad({ id = '', className = '', wrapperClassName = '', onLoadHandle }) {
  const transition = useTransitionState()
  logger.debug(
    `Ad id=[${id}] class=[${className}] browser=[${process.browser}] transition=[${transition.isTransitioning}]`
  )

  const [gamConfig, setGamConfig] = useState({
    googletag: {},
    gamData: {}
  })
  // const isBrowser = typeof window !== 'undefined'
  // Append a random suffix to the ad slot id to create a uniq id for this slot
  // This means we can reuse a slot configuration across multiple
  // invocations on the page as they will each have a unique element and slot id
  // This is important where we insert ads every N blocks/lines of content
  // the total number of ads will not be known in advance

  const adElementId = id + '_' + transition.coorelator
  const wrapId = `wrap_${adElementId}`

  const adConfig = gamConfig.gamData?.slots?.[id]
  const adElRef = useRef()

  // We need to destroy the slot on unmount
  // as each slot will be recreated when the component is mounted again
  // Redefining an existing ad slot throws a GPT exception
  const adClassName = id.replace(/_/g, '-')

  const adResponse = useAdSlot({
    id: adElementId,
    config: adConfig,
    adPath: gamConfig.gamData?.gamAdUnitPath,
    transition: transition,
    adElRef: adElRef
  })

  const [adSize, setAdSize] = useState(null)

  useEffect(() => {
    if (isFunction(onLoadHandle)) {
      onLoadHandle(adResponse)

      // Determine the Ad's size
      const height = adResponse.targetDimensions?.[1]
      if (height) {
        setAdSize(determineAdSize(height))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adResponse])

  useEffect(() => {
    const { googletag, gamData } = window || {}
    if (typeof window !== 'undefined') {
      const validConfig = gamData && gamData.slots && gamData.slots[id]
      if (validConfig) {
        setGamConfig({
          gamData,
          googletag
        })
      } else {
        logger.error(`ads - an invalid slot id was passed or no slot config for ${id}`)
      }
    }

    return () => {
      googletag &&
        googletag.pubadsReady &&
        googletag
          .pubads()
          .getSlots()
          .forEach((slot) => {
            const thisSlot = slot.getSlotId().getDomId()
            if (thisSlot === adElementId) {
              googletag.cmd.push(function () {
                googletag.destroySlots([slot])
              })
            }
          })
    }
  }, [adElementId, id])

  if (isEmpty(gamConfig.gamData) || isEmpty(gamConfig.googletag)) {
    return null
  }

  return (
    /* Adding section to prevent HTML structure breaking */
    <section
      id={wrapId}
      className={cx('gam-ad-section', `${adClassName}-section`, [wrapperClassName || null], {
        [styles['d-ad--large']]: adSize === AdSize.large
      })}
      suppressHydrationWarning
    >
      <div
        id={adElementId}
        data-adunit-id={id}
        ref={adElRef}
        className={cx(`gam-ad ${adClassName} ${className || ''}`, id)}
      />
    </section>
  )
}

Ad.propTypes = {
  className: PropTypes.string,
  id: PropTypes.oneOf([
    'gam_oop_pos0',
    'gam_roofrack_pos1',
    'gam_roofrack_pos2',
    'gam_roofrack_hs_pos1',
    'gam_inreads_gallery_pos1',
    'gam_roofrack_gallery_pos1',
    'gam_inreads_pos2',
    'gam_inreads_pos3',
    'gam_inreads_pos4',
    'gam_inreads_pos5',
    'gam_inreads_pos6',
    'gam_inreads_pos7',
    'gam_inreads_pos8',
    'gam_inreads_pos9',
    'gam_inreads_pos10',
    'gam_inreads_pos11',
    'gam_inreads_pos12',
    'gam_gallery_pos1',
    'gam_gallery_pos2',
    'gam_gallery_pos3',
    'gam_gallery_pos4',
    'gam_gallery_pos5',
    'gam_gallery_pos6',
    'gam_gallery_pos7',
    'gam_gallery_pos8',
    'gam_gallery_pos9',
    'gam_gallery_pos10',
    'gam_gallery_pos11',
    'gam_gallery_pos12',
    'gam_inreads_home_pos5',
    'gam_annex_posR1',
    'gam_annex_posR2',
    'gam_annex_posR3',
    'gam_annex_posR4',
    'gam_annex_posR5',
    'gam_annex_gallery_posR1',
    'gam_stickydesktop_split_pos1',
    'gam_stickydesktop_split_pos2',
    'gam_stickydesktop_split_pos3',
    'gam_stickymobile_split_pos1',
    'gam_stickymobile_split_pos2',
    'gam_stickymobile_split_pos3',
    'gam_fixedresponsive_split_pos1',
    'gam_fixedresponsive_split_pos2',
    'gam_fixedresponsive_split_pos3',
    'gam_fixedresponsive_split_pos4',
    'gam_fixedresponsive_split_pos5',
    'gam_fixedresponsive_split_pos6',
    'gam_textfixed_pos1',
    'gam_textfixed_pos2',
    'gam_texttransact_pos1',
    'gam_texttransact_pos2',
    'gam_texttransact_pos3',
    'gam_featsidebar_pos1',
    'gam_featblock_pos1',
    'gam_featblock_pos2',
    'gam_feattile_pos1',
    'gam_feattile_pos2',
    'gam_feattile_pos3',
    'gam_featblockvoucher_pos1',
    'gam_sponsored_content_pos1',
    'gam_inreads_CFS_pos2',
    'gam_inreads_CFS_pos3',
    'gam_inreads_CFS_pos4',
    'gam_inreads_CFS_pos5',
    'gam_inreads_CFS_pos6',
    'gam_inreads_CFS_pos7',
    'gam_listing_posR1',
    'gam_cta_button_pos1',
    'gam_cta_button_pos2',
    'gam_cta_button_pos3'
  ]).isRequired
}

export default Ad
